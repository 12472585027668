// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCL6G3r6cgAxuRDLqEpm0kU81DEV3JWD3E",
  authDomain: "portfolio-a03d6.firebaseapp.com",
  projectId: "portfolio-a03d6",
  storageBucket: "portfolio-a03d6.appspot.com",
  messagingSenderId: "521812963804",
  appId: "1:521812963804:web:130e81e9eae1c78b565f19",
  measurementId: "G-HM4786DS4S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;
