import React from "react";
import styles from "./AboutHeader.module.css";
import Fade from "react-reveal/Fade";

function AboutHeader() {
  return (
    <>
      {/* About Header */}
      <div
        className={`${styles.AboutChildContainer} ${styles.AboutHeaderContainer}`}
      >
        <div className={styles.AboutHeader}>
          <Fade up delay={300} distance={"5em"}>
            <div className={styles.h3}>A few words about me</div>
          </Fade>
          <Fade up delay={600}>
            <div className={styles.h1}>
              I'm Muhammad Faraz, a multidisciplinary developer who focuses on
              telling my clients’ stories visually, through enjoyable and
              meaningful experiences. I specialize in responsive websites,
              mobile applications and functional user interfaces.
            </div>
          </Fade>
          <Fade up delay={900}>
            <div className={styles.text}>
              Over the past 2 years I have been working with great clients and
              software houses as a developer, Working solo and leading small
              teams. In my spare time I enjoy gaming, doing programming
              challenges and motorcycle adventures.
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
}

export default AboutHeader;
