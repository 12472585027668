import React, { useState } from "react";
import classes from "./Login.module.css";
import logo from "../../assets/favicon.png";
import loader from "../../assets/loader.svg";
import { ContextFunction } from "../../Context/ContextProvider";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const obj = ContextFunction();
  const { signIn, authSigninLoading, authSigninError } = obj;
  const submitHandler = (e) => {
    e.preventDefault();
    signIn(email, password);
  };
  return (
    <main className={classes.signInMain + " text-center"}>
      <form className={classes.signInForm} onSubmit={submitHandler}>
        <img className={"mb-4"} src={logo} alt="" width={102} height={87} />
        <h1 className={"h3 mb-3 fw-normal"}>Login</h1>
        <div className={"form-floating"}>
          <input
            required
            type="email"
            className={"form-control"}
            id="floatingInput"
            placeholder="name@example.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label htmlFor="floatingInput">Email address</label>
        </div>
        <div className={"form-floating mt-2"}>
          <input
            required
            minLength={8}
            type="password"
            className={"form-control"}
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
          <Link to={"/signup"} className={"text-primary " + classes.flatButton}>
            Dont have an account? Register
          </Link>
          <div className={"checkbox"}>
            <label>
              <input type="checkbox" defaultValue="remember-me" /> Remember me
            </label>
          </div>
        </div>
        {authSigninError !== "" ? (
          <div className="text-danger">{authSigninError}</div>
        ) : (
          <></>
        )}
        {authSigninLoading ? (
          <div>
            <img width={90} height={90} src={loader} alt={"loading!"} />
          </div>
        ) : (
          <button className={"w-100 btn btn-lg btn-primary"} type={"submit"}>
            Login
          </button>
        )}

        <p className={"mt-5 mb-3 text-muted"}>© 2019–2021</p>
      </form>
    </main>
  );
};

export default Login;
