import React, { useEffect, useState } from "react";
import styles from "./comments.module.css";
import { CommentSection } from "react-comments-section";
import "react-comments-section/dist/index.css";
import axios from "axios";
import { ContextFunction } from "../../Context/ContextProvider";
import { Link } from "react-router-dom";
function Comments({ routeName }) {
  // Existing comments from database
  const obj = ContextFunction();
  const { isLoggedIn } = obj;
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios.get(`/comments/${routeName}`).then((res) => {
      if (res.data !== undefined) {
        console.log(res.data);
        console.log(res.data.comments);
        setComments(res.data.comments);
      }
      setLoading(false);
    });
  }, [routeName]);
  // Comments in current state

  // Posts new comment, appends to data as parent
  const onSubmitFunction = (data) => {
    let result = {
      comId: data.comId,
      avatarUrl: `https://ui-avatars.com/api/name=${
        isLoggedIn().email[0]
      }&background=random`,
      userProfile: null,
      fullName: data.fullName,
      text: data.text,
      replies: [],
    };
    axios.post(`/comments/${routeName}`, result).then((res) => {
      console.log(res);
    });
  };

  // Reply, append to parent where comId = repliedToCommentId
  const onReplyFunction = (data) => {
    let result = {
      repliedToCommentId: data.repliedToCommentId,
      avatarUrl: `https://ui-avatars.com/api/name=${
        isLoggedIn().email[0]
      }&background=random`,
      userProfile: null,
      fullName: data.fullName,
      text: data.text,
      comId: data.comId,
    };
    axios.patch(`/comments/${routeName}`, result).then((res) => {
      console.log(res);
    });
  };

  // Edit, find where comId = comId, and update text
  const onEditFunction = (data) => {
    let result = {
      comId: data.comId,
      text: data.text,
    };
    axios.put(`/comments/${routeName}`, result).then((res) => {
      console.log(res);
    });
  };

  // Delete, delete object where comId = comIdToDelete
  const onDeleteFunction = (data) => {
    console.log(data);
    let result = {
      comIdToDelete: data.comIdToDelete,
    };
    axios.post(`/comments/${routeName}/del`, result).then((res) => {
      console.log(res);
    });
  };

  // Custom no comment data
  const customNoComment = () =>
    loading ? (
      <>Loading Comments</>
    ) : (
      <div className="no-com">No comments. Be the first!</div>
    );

  return (
    <div style={{ width: "100%" }} className={styles.commentsContainer}>
      {isLoggedIn() != null ? (
        <CommentSection
          currentUser={{
            currentUserImg: `https://ui-avatars.com/api/name=${
              isLoggedIn().email[0]
            }&background=random`,
            currentUserFullName: isLoggedIn().email,
          }}
          submitBtnStyle={{
            border: "1px solid black",
            backgroundColor: "#0f141e",
          }}
          cancelBtnStyle={{
            border: "1px solid #4e4e4e",
            backgroundColor: "#4e4e4e",
            color: "white",
          }}
          removeEmoji={true}
          commentData={comments}
          customNoComment={() => customNoComment()}
          onSubmitAction={(data) => onSubmitFunction(data)}
          onReplyAction={(data) => onReplyFunction(data)}
          onEditAction={(data) => onEditFunction(data)}
          onDeleteAction={(data) => onDeleteFunction(data)}
        />
      ) : (
        <div
          className={
            styles.lockComms +
            " d-flex justify-content-center align-items-center flex-column"
          }
        >
          <p className="mb-3 ">To view or post comments</p>
          <div>
            <Link className="btn btn-lg btn-dark mb-3 w-100" to="/login">
              Login
            </Link>
            <Link className="btn btn-lg btn-light mb-3 w-100" to="/signup">
              Register
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Comments;
