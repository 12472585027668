import React, { useState } from "react";
import classes from "./Signup.module.css";
import logo from "../../assets/favicon.png";
import loader from "../../assets/loader.svg";
import { ContextFunction } from "../../Context/ContextProvider";
import { Link } from "react-router-dom";
const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [confirmPassErr, setConfirmPassErr] = useState("");

  const obj = ContextFunction();
  const { signUp, authSignupLoading, authSignupError } = obj;
  const submitHandler = (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      signUp(email, password);
      setConfirmPassErr("");
    } else {
      setConfirmPassErr("Passwords do not match");
    }
  };
  return (
    <main className={classes.signInMain + " text-center"}>
      <form className={classes.signInForm} onSubmit={submitHandler}>
        <img className={"mb-4"} src={logo} alt="" width={102} height={87} />
        <h1 className={"h3 mb-3 fw-normal"}>Signup</h1>
        <div className={"form-floating"}>
          <input
            type="email"
            className={"form-control"}
            id="floatingInput"
            placeholder="name@example.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label htmlFor="floatingInput">Email address</label>
        </div>
        <div className={"form-floating mt-2"}>
          <input
            type="password"
            className={"form-control"}
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Password</label>
        </div>
        <div className={"form-floating mt-2"}>
          <input
            type="password"
            className={"form-control"}
            id="floatingPassword"
            placeholder="Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <label htmlFor="floatingPassword">Confirm Password</label>
        </div>
        {confirmPassErr !== "" ? (
          <div className={classes.errform + " text-danger"}>
            Passwords donot match!
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-between align-items-center mb-3 mt-2">
          <Link to={"/login"} className={"text-primary " + classes.flatButton}>
            Already have an account?
          </Link>
          <div className={"checkbox"}>
            <label>
              <input type="checkbox" defaultValue="remember-me" />
              Agree to terms and conditions
            </label>
          </div>
        </div>
        {authSignupError !== "" ? (
          <div className="text-danger">{authSignupError}</div>
        ) : (
          <></>
        )}
        {authSignupLoading ? (
          <div>
            <img height={90} width={90} src={loader} alt={"loading!"} />
          </div>
        ) : (
          <button className={"w-100 btn btn-lg btn-primary"} type={"submit"}>
            Register
          </button>
        )}

        <p className={"mt-5 mb-3 text-muted"}>© 2019–2021</p>
      </form>
    </main>
  );
};

export default Signup;
