import React, { useEffect, useState } from "react";
import { ContextFunction } from "../../Context/ContextProvider";
import classes from "./Profile.module.css";
import placeholder from "../../assets/placeholderProf.jpeg";
import loader from "../../assets/loader.svg";
import { Snackbar, Alert } from "@mui/material";
import {
  AiOutlineClose,
  AiFillSave,
  AiOutlineLogout,
  AiOutlineEdit,
  AiOutlineCheck,
} from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
const Profile = () => {
  const obj = ContextFunction();
  const { isLoggedIn, signOut, userProfile, updateProfile, updateLoading } =
    obj;
  const [isEditMode, setIsEditMode] = useState(false);
  const [tagList, setTagList] = useState(userProfile.tags);

  const [profileName, setProfileName] = useState(
    userProfile.displayName ?? userProfile.email
  );
  const [bio, setBio] = useState(userProfile.bio ?? "No bio yet!");
  const [phone, setPhone] = useState("");
  const [tag, setTag] = useState("");
  const [addingTag, setAddingtag] = useState(false);
  const onSaveHandler = (email, tglst, bIO, prof, phn) => {
    console.log(email, tglst, bIO, prof, phn);
    updateProfile(email, tglst, bIO, prof, phn).then(() => {
      setIsEditMode(false);
    });
  };

  const addtoListHandler = (value) => {
    if (value.length !== 0) {
      setTagList([...tagList, value]);
    }
  };
  const removeFromList = (value) => {
    if (value.length !== 0) {
      setTagList(
        tagList.filter((data) => {
          return data !== value;
        })
      );
    }
  };

  return (
    <main className={""}>
      <Snackbar open={false} autoHideDuration={6000}>
        <Alert onClose={() => {}} severity="info" sx={{ width: "100%" }}>
          Updating
        </Alert>
      </Snackbar>
      <Snackbar open={false} autoHideDuration={6000}>
        <Alert onClose={() => {}} severity="success" sx={{ width: "100%" }}>
          Successfully Updated!
        </Alert>
      </Snackbar>
      <div className={classes.main + " row g-0"}>
        <div
          className={"col-6 align-self-center py-5 px-3 " + classes.gridCol1}
        >
          <img
            className={classes.profilePic}
            src={placeholder}
            alt="a profile pic"
          />
        </div>
        <div
          className={"col-6 align-self-center py-5 px-3 " + classes.gridCol2}
        >
          <div className={classes.profileAppBar}>
            <div className={classes.tagContainer}>
              {tagList.length <= 0 ? (
                isEditMode ? (
                  addingTag ? (
                    <div className={"d-flex align-items-center"}>
                      <div className={classes.addInput}>
                        <input
                          type="text"
                          maxLength={18}
                          onChange={(e) => {
                            setTag(e.target.value);
                          }}
                        />
                      </div>

                      <div
                        onClick={() => {
                          addtoListHandler(tag);
                          setAddingtag(false);
                        }}
                        className={"mx-2 " + classes.addBtn}
                      >
                        <AiOutlineCheck fontSize={16} />
                      </div>
                    </div>
                  ) : (
                    <div className={"d-flex align-items-center"}>
                      <div className="">No Tags</div>

                      <div
                        onClick={() => {
                          setAddingtag(true);
                        }}
                        className={"mx-2 " + classes.addBtn}
                      >
                        <IoMdAdd fontSize={16} />
                      </div>
                    </div>
                  )
                ) : (
                  "No Tags"
                )
              ) : (
                <>
                  {tagList.map((tag) => {
                    return (
                      <div
                        className={
                          isEditMode
                            ? classes.tag
                            : classes.tag + " " + classes.tagEdit
                        }
                      >
                        {isEditMode ? (
                          <div
                            onClick={() => {
                              removeFromList(tag);
                            }}
                            className={classes.tagSticker}
                          >
                            <AiOutlineClose fontSize={12} />
                          </div>
                        ) : (
                          <></>
                        )}
                        {tag}
                      </div>
                    );
                  })}
                  {isEditMode && tagList.length < 3 ? (
                    addingTag ? (
                      <div className="d-flex">
                        <div className={classes.addInput}>
                          <input
                            type="text"
                            maxLength={18}
                            onChange={(e) => {
                              setTag(e.target.value);
                            }}
                          />
                        </div>
                        <div
                          onClick={() => {
                            addtoListHandler(tag);
                            setAddingtag(false);
                          }}
                          className={"mx-2 " + classes.addBtn}
                        >
                          <AiOutlineCheck fontSize={16} />
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setAddingtag(true);
                        }}
                        className={"mx-2 " + classes.addBtn}
                      >
                        <IoMdAdd fontSize={16} />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div className={classes.actions}>
              <div
                className="me-2"
                onClick={() => {
                  if (isEditMode) {
                    onSaveHandler(
                      isLoggedIn().email,
                      tagList,
                      bio,
                      profileName,
                      phone
                    );
                  } else {
                    setIsEditMode(true);
                  }
                }}
              >
                {isEditMode ? (
                  updateLoading ? (
                    <img src={loader} alt="" width={28} height={28} />
                  ) : (
                    <AiFillSave fontSize={28} />
                  )
                ) : (
                  <AiOutlineEdit fontSize={28} />
                )}
              </div>
              <div
                onClick={() => {
                  signOut();
                }}
              >
                <AiOutlineLogout fontSize={28} />
              </div>
            </div>
          </div>
          {isEditMode ? (
            <div className={classes.displayName + " my-3 " + classes.editInput}>
              <label className={classes.floatingLabel}>Change name</label>
              <input
                type="text"
                maxLength={20}
                value={profileName}
                onChange={(e) => {
                  setProfileName(e.target.value);
                }}
              />
            </div>
          ) : (
            <div className={classes.displayName + " my-3"}>
              {isLoggedIn().displayName ?? profileName}
            </div>
          )}
          {isEditMode ? (
            <div className={classes.bio + " " + classes.editInput}>
              <label className={classes.floatingLabel}>Change Bio</label>
              <textarea
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
                rows="4"
                maxLength={180}
              ></textarea>
            </div>
          ) : (
            <div className={classes.bio}>
              {bio}
              {/* Should be max 180 */}
            </div>
          )}
          <div className={classes.emailSect + " d-flex mt-4 mt-2 "}>
            <div className={"me-1"} style={{ fontWeight: "bold" }}>
              Email:
            </div>
            <div>{isLoggedIn().email}</div>
          </div>
          <div
            className={
              isEditMode
                ? classes.phoneSect + " d-flex my-3 " + classes.editInput
                : classes.phoneSect + " d-flex my-3 "
            }
          >
            <div className={"me-1"} style={{ fontWeight: "bold" }}>
              Ph#:
            </div>
            {isEditMode ? (
              <input
                type="number"
                maxLength={17}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  console.log(phone);
                }}
              />
            ) : (
              <div>
                {console.log(phone)}
                {phone.length}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
