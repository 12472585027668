import React from "react";
import { BrowserRouter } from "react-router-dom";
import { InputProvider } from "./Context/ContextProvider";
import RouterComponent from "./Components/Router/RouterComponent";
import "./App.module.css";
function App() {
  return (
    <>
      <InputProvider>
        <BrowserRouter>
          <RouterComponent />
        </BrowserRouter>
      </InputProvider>
    </>
  );
}

export default App;
