import React, { useContext, useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import db from "./firebase";

const Context = React.createContext();

export function ContextFunction() {
  return useContext(Context);
}

export function InputProvider({ children }) {
  // States
  const [closeIcon, setCloseIcon] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [index, setIndex] = useState(8);
  const [projectsArray, setProjectsArray] = useState([]);

  const [user, setUser] = useState();
  const [userProfile, setUserProfile] = useState({
    email: "",
    tags: [],
    bio: "",
    displayName: "",
    phoneNo: 0,
  });
  const [authSigninLoading, setAuthSigninLoading] = useState(false);
  const [authSignupLoading, setAuthSignupLoading] = useState(false);
  const [authSigninError, setAuthSigninError] = useState("");
  const [authSignupError, setAuthSignupError] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [viewsData, setViewsData] = useState([]);

  const errorSetter = (e) => {
    switch (e) {
      case "auth/email-already-exists":
        return "Email aready exists!";
      case "auth/wrong-password":
        return "Invalid password!";
      case "auth/user-not-found":
        return "Email doesn't exists";
      default:
        return e;
    }
  };
  // Refs used for form values and scroll pointer
  let projectsRef = useRef("");
  let form = useRef();

  // Handle form change
  const handleChange = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // Handle form submit and send email to given email
  const handleSubmit = (e) => {
    e.preventDefault();
    // Async loader while request is sent
    setFormLoading(true);
    sendEmail();
  };

  // Resets all Form Values
  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach(
      (select) => (select.value = select.options[0].value)
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );
    setFormValues({});
  };

  // Takes form inputs and sends email with them using emailJS for email template
  // Have to sign up with emailJS, give your service key, template key, and public key for them to send emails to you
  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_11jjs3m",
        "template_hek4cor",
        form.current,
        "2ToBi-pe_SaD3D74r"
      )
      .then(
        (result) => {
          // show the user a success message
          handleReset();
          setFormLoading(false);
          setSubmitted(true);
        },
        (error) => {
          // show the user an error
          setFormLoading(false);
          setFailed(true);
        }
      );
  };

  // Initial value for localstorage
  const storedObject = JSON.parse(localStorage.getItem("data")) || [];
  const [UserObject, setUserObject] = useState(storedObject);
  const getViews = async () => {
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((docs) => {
      viewsData.push(docs.data());
    });
    setViewsData(viewsData);
  };

  // Updates localstorage value every time UserObject changes
  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(UserObject));
  }, [UserObject]);
  // Import the functions you need from the SDKs you need
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCL6G3r6cgAxuRDLqEpm0kU81DEV3JWD3E",
    authDomain: "portfolio-a03d6.firebaseapp.com",
    projectId: "portfolio-a03d6",
    storageBucket: "portfolio-a03d6.appspot.com",
    messagingSenderId: "521812963804",
    appId: "1:521812963804:web:130e81e9eae1c78b565f19",
    measurementId: "G-HM4786DS4S",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    onAuthStateChanged(auth, async (usr) => {
      if (usr) {
        setUser(usr);

        const docRef = doc(db, "account", usr.email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserProfile(docSnap.data());
        }
      } else {
        setUser(null);
        setUserProfile({});
      }
    });
    setUser(auth.currentUser);
  }, []);

  const isLoggedIn = () => {
    return user;
  };

  const signIn = (email, pass) => {
    setAuthSigninLoading(true);
    signInWithEmailAndPassword(auth, email, pass)
      .then(async (creds) => {
        setUser(creds.user);
        const docRef = doc(db, "account", email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserProfile(docSnap.data());
          setAuthSigninLoading(false);
        }
      })
      .catch((err) => {
        setAuthSigninError(errorSetter(err.code));
        setAuthSigninLoading(false);
      });
  };
  const updateProfile = async (email, tags, bio, displayName, phoneNo) => {
    setUpdateLoading(true);
    const updatedProfile = {
      email: email,
      tags: tags,
      bio: bio,
      displayName: displayName,
      phoneNo: phoneNo,
    };
    setUserProfile(updatedProfile);
    await setDoc(doc(db, "account", email), updatedProfile);
    setUpdateLoading(false);
  };

  const signUp = (email, pass) => {
    setAuthSignupLoading(true);
    createUserWithEmailAndPassword(auth, email, pass)
      .then(async (creds) => {
        const newUser = {
          email: email,
          tags: [],
          bio: "",
          displayName: "",
          phoneNo: 0,
        };
        await setDoc(doc(db, "account", email), newUser);
        setUser(creds.user);
        setUserProfile(newUser);
        setAuthSignupLoading(false);
      })
      .catch((err) => {
        setAuthSignupError(errorSetter(err.code));
        setAuthSignupLoading(false);
      });
  };
  const signOutHandler = () => {
    signOut(auth);
  };

  let obj = {
    user: user,
    closeIcon: closeIcon,
    setCloseIcon: setCloseIcon,
    projectsRef: projectsRef,
    formValues: formValues,
    setFormValues: setFormValues,
    handleSubmit: handleSubmit,
    handleChange: handleChange,
    submitted: submitted,
    formLoading: formLoading,
    failed: failed,
    form: form,
    UserObject,
    setUserObject,
    index: index,
    setIndex: setIndex,
    projectsArray: projectsArray,
    setProjectsArray: setProjectsArray,
    isLoggedIn: isLoggedIn,
    authSigninLoading: authSigninLoading,
    authSignupLoading: authSignupLoading,
    authSigninError: authSigninError,
    authSignupError: authSignupError,
    signIn: signIn,
    signUp: signUp,
    signOut: signOutHandler,

    updateLoading: updateLoading,
    updateProfile: updateProfile,
    userProfile: userProfile,

    getViews: getViews,
    viewsData: viewsData,
  };

  return <Context.Provider value={obj}>{children}</Context.Provider>;
}
