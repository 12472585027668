const AboutData = {
  Services: [
    {
      name: "Web App Developer",
    },
    {
      name: "Mobile App Developer",
    },
    {
      name: "Desktop App Developer",
    },
    {
      name: "UX & UI",
    },
    {
      name: "Designing",
    },
    {
      name: "Content Writing",
    },
  ],
  Tools: [
    {
      name: "Visual Studio",
    },
    {
      name: "Photoshop",
    },
    {
      name: "VS code",
    },
    {
      name: "Adobe XD",
    },
    {
      name: "Postman",
    },
    {
      name: "Figma",
    },
    {
      name: "Xcode",
    },
    {
      name: "Xampp",
    },
    {
      name: "AndroidStudio",
    },
    {
      name: "Linux/Apache2",
    },
  ],
  Positions: [
    {
      h1: "Quell x Code",
      text: "Intern Developer",
      date: "Oct 2021 — Jan 2022",
    },
    {
      h1: "Self Employeed",
      text: "Freelancer",
      date: "July 2022 — Present",
    },
  ],
};

export default AboutData;
