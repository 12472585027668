const ProjectsData = [
  {
    route: "weather-me",
    name: "Weather Me",
    client: "Mayalight",
    date: "2022-2022",
    views: "10",
    header: "WeatherMe - Weather app for Android & iOS",
    text: [
      {
        p: "The weather app also provides atmospheric pressure, weather conditions, visibility distance, relative humidity, precipitation in different unites, dew point, wind speed and direction, in addition to ten days in future and hourly weather forecast.",
      },
    ],
    roles: [
      {
        role: "Creative Design",
      },
      {
        role: "UX / UI",
      },
      {
        role: "Mobile app",
      },
      {
        role: "Firebase",
      },
    ],
    img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F1b%2F60%2F6f%2F1b606f67ffc2ba4dffd203d632430204.jpg&f=1&nofb=1",
    images: [
      {
        original: require("./../assets/projects/projectWeather/2.jpg"),
      },
      {
        original: require("./../assets/projects/projectWeather/3.jpg"),
      },
      {
        original: require("./../assets/projects/projectWeather/1.jpg"),
      },
      {
        original: require("./../assets/projects/projectWeather/4.jpg"),
      },
    ],
  },
  {
    route: "freelancer-io",
    name: "FreelancerIO",
    client: "Saad Ali",
    date: "2020-2020",
    views: "24",
    header: "Freelancer IO - A perfect place to start your career!",
    text: [
      {
        p: 'FreelancerIo is a global online marketplace for freelance services. FreelancerIo’s platform connects freelancers (sellers) to people or businesses looking to hire (buyers). Listings on FreelancerIo are diverse and range from "get a well-designed business card" to "help with HTML, JavaScript, CSS, and jQuery".The highest-paying jobs on FreelancerIo include website design, social media manager, proofreading and copywriting, and resume writing. FreelancerIo takes its name from the $5 asking price attached to all tasks when the company was founded in 2010 in Tel Aviv. These days many sellers charge much more, and the site is increasingly bringing its freelancers high income.',
      },
      {
        p: "Launched in 2020, FreelancerIo Business is designed to help teams at larger companies manage their work with freelancers. Users can create projects, assign team members and set freelance budgets, then actually hire freelancers, as well as offer internal and external feedback on the work that comes in. FreelancerIo Business also made it easier for companies to conduct remote work as more people did business from home during the Covid-19 pandemic and beyond.",
      },
    ],
    roles: [
      {
        role: "Creative Direction",
      },
      {
        role: "UX / UI",
      },
      {
        role: "App Design",
      },
      {
        role: "Business",
      },
    ],
    img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F1b%2F60%2F6f%2F1b606f67ffc2ba4dffd203d632430204.jpg&f=1&nofb=1",
    images: [
      {
        original: require("./../assets/projects/projectUi1/1.jpg"),
      },
      {
        original: require("./../assets/projects/projectUi1/2.jpg"),
      },
      {
        original: require("./../assets/projects/projectUi1/3.jpg"),
      },
    ],
  },
  {
    route: "photo-gallery",
    name: "Photo Gallery",
    client: "Rabeea Aftab",
    date: "2017-2018",
    views: "5",
    header: "Photo Gallery. Just the app for you beautiful pictures.",
    text: [
      {
        p: "Your photos are automatically organized and searchable so you can easily find the photo you're looking for. Easily save and share what matters.",
      },
      {
        p: "Photo Gallery is a powerful image gallery with a list of advanced options for creating responsive image galleries with beautiful lightbox.",
      },
    ],
    roles: [
      {
        role: "UX / UI",
      },
      {
        role: "iOS App Design",
      },
      {
        role: "Personalization",
      },
    ],
    img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F1b%2F60%2F6f%2F1b606f67ffc2ba4dffd203d632430204.jpg&f=1&nofb=1",
    images: [
      {
        original: require("./../assets/projects/projectUi2/1.jpg"),
      },
      {
        original: require("./../assets/projects/projectUi2/2.jpg"),
      },
    ],
  },
  {
    route: "your-way",
    name: "Your Way!",
    client: "Mayalight",
    date: "2022-2022",
    views: "124",
    header: "Your Way! Travel The Way You Like",
    text: [
      {
        p: "Travelers are becoming more independent and less reliant on travel agents. Travel technology now helps millions of people book flights and hotel rooms and rent cars. People all over the world are comfortable planning entire trips using mobile devices. In fact, 48 percent of US travelers search, book, and plan trips using only a mobile device.",
      },
      {
        p: " Your Way allows visitors to track prices on flights and hotels, organize travel information into itineraries, and choose flights according to their budgets. To let users compare prices for various travel products",
      },
    ],
    roles: [
      {
        role: "Creative Direction",
      },
      {
        role: "UX / UI",
      },
      {
        role: "App Design",
      },
      {
        role: "Android, iOS",
      },
      {
        role: "Business",
      },
    ],
    img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F1b%2F60%2F6f%2F1b606f67ffc2ba4dffd203d632430204.jpg&f=1&nofb=1",
    images: [
      {
        original: require("./../assets/projects/projectUi3/1.jpg"),
      },
      {
        original: require("./../assets/projects/projectUi3/2.jpg"),
      },
      {
        original: require("./../assets/projects/projectUi3/3.jpg"),
      },
    ],
  },
  {
    route: "hijab-eccom",
    name: "Hijab World",
    client: "Imran Khan",
    date: "2021-2022",
    views: "89",
    header: "Hijab World - Trusted E-commerce app to purchase amazing goods!",
    text: [
      {
        p: "Shop all branded items you want at Hijab World 10/10 Sale and enjoy:\n1. Free shipping\n2. Vouchers for First Orders\n3. Extra Vouchers with Hijab World Bonus\nLet Hijab World give you everything you want and more, especially with the best deals and complete selection of items that you want and adore.",
      },
    ],
    roles: [
      {
        role: "E-commerce",
      },
      {
        role: "UX / UI",
      },
      {
        role: "Website",
      },
      {
        role: "Business",
      },
    ],
    img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F1b%2F60%2F6f%2F1b606f67ffc2ba4dffd203d632430204.jpg&f=1&nofb=1",
    images: [
      {
        original: require("./../assets/projects/projectHijab/1.jpg"),
      },
      {
        original: require("./../assets/projects/projectHijab/2.jpg"),
      },
      {
        original: require("./../assets/projects/projectHijab/3.jpg"),
      },
      {
        original: require("./../assets/projects/projectHijab/4.jpg"),
      },
    ],
  },
];

export default ProjectsData;
